jQuery( document ).ready(function( $ ) {


    // ---------------  AOS animations initialise  ---------------//
    AOS.init({
      once: true,
      delay: 0,
      throttleDelay: 0
    });


    jQuery('#preloader-fadeout').addClass('active');

    jQuery('#meniu a, a.btn, a.navbar-brand, #cookie-notice a, button.navbar-toggler').on('click', function (e) {
      e.preventDefault();
      var goTo = this.getAttribute("href");
      if (goTo) {
        jQuery('#preloader-up').addClass('active');
        setTimeout(function(){
            window.location = goTo;
        }, 500);
      }
    });


    if (jQuery(window).width() > 600) {
      var windowWidth = jQuery(window).width() / 2;
    } else {
      var windowWidth = jQuery(window).width() / 1.3;
    }
    var containerWidth = jQuery('section.portfolio.odd .slider').width();
    var sliderWidth = -(windowWidth-containerWidth);
    jQuery('section.portfolio .swiper-container').css("width",windowWidth);
    if (jQuery(window).width() > 768) {
      jQuery('section.portfolio.odd .swiper-container').css('transform','translateX(' + sliderWidth + 'px)');
    }
jQuery(window).resize(function(){
    if (jQuery(window).width() > 600) {
      var windowWidth = jQuery(window).width() / 2;
    } else {
      var windowWidth = jQuery(window).width() / 1.3;
    }
    var containerWidth = jQuery('section.portfolio.odd .slider').width();
    var sliderWidth = -(windowWidth-containerWidth);
    jQuery('section.portfolio .swiper-container').css("width",windowWidth);
    if (jQuery(window).width() > 768) {
      jQuery('section.portfolio.odd .swiper-container').css('transform','translateX(' + sliderWidth + 'px)');
    }
});



  // Custom cursor

  (function () {

    var isSafari = /constructor/i.test(window.HTMLElement) || function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    }(!window['safari'] || typeof safari !== 'undefined' && safari.pushNotification);

    var $bigBall = document.querySelector('.cursor__ball--big');
    //var $smallBall = document.querySelector('.cursor__ball--small');
    var $hoverables = document.querySelectorAll('a, button, a.btn, .swiper-button-next, .swiper-button-prev, input, textarea');

    // Listeners
    document.body.addEventListener('mousemove', onMouseMove);
    for (var i = 0; i < $hoverables.length; i++) {
      $hoverables[i].addEventListener('mouseenter', onMouseHover);
      $hoverables[i].addEventListener('mouseleave', onMouseHoverOut);
    }

    // Move the cursor
    function onMouseMove(e) {
      // if(isSafari === false) {
     TweenMax.to($bigBall, .1, {
      left: e.pageX - 0,
      top: e.pageY -  window.scrollY - 0,
      });
      // TweenMax.to($smallBall, .1, {
      //   left: e.pageX - 5,
      //   top: e.pageY -  window.scrollY - 7,
      // });
    }

    // Hover an element
    function onMouseHover() {
      $($bigBall).addClass('hovered');
    }
    function onMouseHoverOut() {
      $($bigBall).removeClass('hovered');
    }
  })();

  // ---------------  Mobile menu animation  ---------------//  

    if (jQuery(window).width() < 992) {
      var counter = 1;
      jQuery('.navbar-nav .menu-item a').each(function(){
        jQuery(this).addClass('aos-init');
        jQuery(this).removeClass('aos-animate');
        jQuery(this).attr('data-aos','fade-up');
        jQuery(this).attr('data-aos-delay','' + counter + '00');
        counter++;
      });
      jQuery('.lang-switch').removeClass('aos-animate');

      jQuery('.navbar-toggler').on('click', function () {
        jQuery('#nav-icon1').toggleClass('open');
        var counter = 1;
        jQuery('.navbar-nav .menu-item a').each(function(){
          if (jQuery(this).hasClass('aos-animate')) {
            jQuery(this).removeClass('aos-animate');
          } else {
            setTimeout(function() {
              jQuery(this).addClass('aos-animate');
            }.bind(this), 0);
          };
            jQuery(this).addClass('aos-init');
            jQuery(this).attr('data-aos','fade-up');
            jQuery(this).attr('data-aos-delay','' + counter + '00');
            counter++;
        });
        jQuery('.lang-switch').removeClass('aos-animate');
        if (jQuery('.lang-switch').hasClass('aos-animate')) {
          jQuery('.lang-switch').removeClass('aos-animate');
        } else {
          setTimeout(function() {
            jQuery('.lang-switch').addClass('aos-animate');
          }.bind(this), 400);
        };

        jQuery('nav.navbar').toggleClass(function(){
          return $(this).is('.open, .closed') ? 'open closed' : 'open';
        })
        jQuery('.lang-switch').toggleClass('open');
      });
    }

  // jQuery(window).resize(function(){

  // });

  // ---------------  WP CF7 form animations and validations  ---------------//
  jQuery('form.wpcf7-form input, form.wpcf7-form textarea').focus(function() {
      jQuery(this).parent().addClass('focused');
  });
  jQuery('form.wpcf7-form input, form.wpcf7-form textarea').focusout(function() {
      jQuery(this).parent().removeClass('focused');
  });

  
  jQuery("form.wpcf7-form input, form.wpcf7-form textarea").each(function(index, element){
      isEmptyFields(element);
  });
  jQuery("form.wpcf7-form input, form.wpcf7-form textarea").on('change focus blur', function (field) {
      jQuery(this).removeClass('wpcf7-not-valid');
      jQuery(this).parent().removeClass('not-valid');
  });
  jQuery('form.wpcf7-form input, form.wpcf7-form textarea').on('change keyup blur', function (field) {
    isEmptyFields(this);
  });

  function isEmptyFields(elm) {
    if( jQuery(elm).val() ) {
          jQuery(elm).parent().addClass('field-not-empty');
      } else {
          jQuery(elm).parent().removeClass('field-not-empty');
      }
  };

  jQuery('.wpcf7-form').ajaxComplete( function() {
      if(jQuery(this).hasClass('invalid') || jQuery(this).hasClass('sent')){
              jQuery(".wpcf7-form-control-wrap").each(function(index, element){
                  jQuery(this).removeClass('not-valid');
              });
              jQuery(".wpcf7-not-valid").each(function(index, element){
                jQuery(this).parent().addClass('not-valid');
            });
      }
    });

  // ---------------  WP CF7 textarea field auto height depending on content  ---------------//
  autosize(document.querySelectorAll('textarea'));

  // ---------------  Our projects page swipers  ---------------//
  jQuery(".swiper-container").each(function(index, element){
      var id = jQuery(this).attr("data-id")
      
      var mySwiper = new Swiper ('.swiper' + id + '', {
          // Optional parameters
          direction: 'horizontal',
          loop: false,
          navigation: {
              nextEl: '.swiper-button-next' + id + '',
              prevEl: '.swiper-button-prev' + id + '',
          },
      })
  });


  // ---------------  On scroll animations  ---------------//
  jQuery(window).scroll(function () {
      jQuery('.animation').each(function () {
          var imagePos = jQuery(this).offset().top;
          var imageHeight = jQuery(this).height() * 3.4;
          var topOfWindow = jQuery(window).scrollTop();

          if (imagePos < topOfWindow + imageHeight && imagePos + imageHeight > topOfWindow) {
              jQuery(this).addClass("animate");
          }
      });
  });

  // ---------------  Cookie notice  ---------------//
  function setCookie(name, value, days) {
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          var expires = "; expires=" + date.toGMTString();
      }
      else var expires = "";               

      document.cookie = name + "=" + value + expires + "; path=/";
  }

  function getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
  }

    var notice = jQuery("#cookie-notice");
    if(notice.length) {
        if(getCookie("cookie-notice") == null) {
          jQuery(notice).addClass('active');
        }
        jQuery(".js-accept-cookies").click(function(){
          jQuery(notice).removeClass('active');
          setCookie("cookie-notice", "accepted", 999);
        });
    }

  // ---------------  Page preloader  ---------------//
  jQuery(window).ready(function() {
    jQuery("#preloader").addClass("loaded");
  });

  // ---------------  Header   ---------------//

  jQuery(document).ready(function(){
    var prevScrollpos = window.pageYOffset;

    function menuOnScroll() {
      var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("navi").classList.add("menu-bg");
        if (window.pageYOffset === 0) {
          document.getElementById("navi").classList.add("toped");
          document.getElementById("navi").classList.remove("scrolled");

        } else {
          document.getElementById("navi").classList.remove("toped");
        }
      } else {
        document.getElementById("navi").classList.add("scrolled");
        if (window.pageYOffset === 0) {
          document.getElementById("navi").classList.add("toped");
          document.getElementById("navi").classList.remove("scrolled");

        } else {
          document.getElementById("navi").classList.remove("toped");
        }
        
      }
      prevScrollpos = currentScrollPos;
    };
    menuOnScroll();
    jQuery(window).scroll(menuOnScroll);
  });


    // ---------------  Footer Google Maps instegration  ---------------//
    function initMap() {
      // The location of Uluru
      var markerPos = {lat: 54.919070, lng: 24.001640};
      var markerPosToCenter = {lat: 54.929070, lng: 24.001640};

      var map = new google.maps.Map(
          document.getElementById('map'), {
            zoom: 14, 
            center: markerPosToCenter, 
            title: 'MB „Mobiliųjų aplikacijų sprendimai“',
            styles:  [ { "featureType": "all", "elementType": "labels.text.fill", "stylers": [ { "saturation": 36 }, { "color": "#9043bc" }, { "lightness": 40 } ] }, { "featureType": "all", "elementType": "labels.text.stroke", "stylers": [ { "visibility": "off" }, { "color": "#000000" }, { "lightness": 16 } ] }, { "featureType": "all", "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [ { "color": "#000000" }, { "lightness": 20 } ] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [ { "color": "#000000" }, { "lightness": 17 }, { "weight": 1.2 } ] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "color": "#2f0249" } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#2f0249" } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#2f0249" }, { "lightness": 17 } ] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#000000" }, { "lightness": 29 }, { "weight": 0.2 } ] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#2f0249" }, { "lightness": 18 } ] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "color": "#0b0210" }, { "lightness": 16 } ] }, { "featureType": "transit", "elementType": "geometry", "stylers": [ { "color": "#2f0249" } ] }, { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#2f0249" }, { "lightness": 17 } ] } ]
          });
      var markerUrl = object_name.templateUrl + '/assets/img/marker.svg';
      var marker = new google.maps.Marker({position: markerPos, map: map, icon: markerUrl});
      
      var Popup = createPopupClass();
      popup = new Popup(
          new google.maps.LatLng(54.919070, 24.001640),
          document.getElementById('content'));
      popup.setMap(map);

      function createPopupClass() {
        /**
         * A customized popup on the map.
         * @param {!google.maps.LatLng} position
         * @param {!Element} content The bubble div.
         * @constructor
         * @extends {google.maps.OverlayView}
         */
        function Popup(position, content) {
          this.position = position;
      
          content.classList.add('popup-bubble');
      
          // This zero-height div is positioned at the bottom of the bubble.
          var bubbleAnchor = document.createElement('div');
          bubbleAnchor.classList.add('popup-bubble-anchor');
          bubbleAnchor.appendChild(content);
      
          // This zero-height div is positioned at the bottom of the tip.
          this.containerDiv = document.createElement('div');
          this.containerDiv.classList.add('popup-container');
          this.containerDiv.appendChild(bubbleAnchor);
      
          // Optionally stop clicks, etc., from bubbling up to the map.
          google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
        }
        // ES5 magic to extend google.maps.OverlayView.
        Popup.prototype = Object.create(google.maps.OverlayView.prototype);
      
        /** Called when the popup is added to the map. */
        Popup.prototype.onAdd = function() {
          this.getPanes().floatPane.appendChild(this.containerDiv);
        };
      
        /** Called when the popup is removed from the map. */
        Popup.prototype.onRemove = function() {
          if (this.containerDiv.parentElement) {
            this.containerDiv.parentElement.removeChild(this.containerDiv);
          }
        };
      
        /** Called each frame when the popup needs to draw itself. */
        Popup.prototype.draw = function() {
          var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
      
          // Hide the popup when it is far out of view.
          var display =
              Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
              'block' :
              'none';
      
          if (display === 'block') {
            this.containerDiv.style.left = divPosition.x + 'px';
            this.containerDiv.style.top = divPosition.y + 'px';
          }
          if (this.containerDiv.style.display !== display) {
            this.containerDiv.style.display = display;
          }
        };
      
        return Popup;
      }
    } 


    setTimeout(function(){ 
      initMap();
     }, 2400); 

     
});
